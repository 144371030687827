@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

section {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

/* Kaydırma çubuğu ana container */
::-webkit-scrollbar {
  width: 10px;
  background-color: #1F2937;
}

/* Kaydırma çubuğu track (arka plan) */
::-webkit-scrollbar-track {
  background-color: #1F2937;
  border-radius: 8px;
}

/* Kaydırma çubuğu thumb (kaydırıcı) */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #8B5CF6, #4ECDC4);
  border-radius: 8px;
  border: 2px solid #1F2937;
}

/* Hover durumunda kaydırma çubuğu */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, #7C3AED, #34D399);
} 